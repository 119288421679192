<template>
  <b-modal id="dossierModal" ref="dossierModal" @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("Dossier") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRE">
      <div class="center">
        <b-form-group :label="$t('NAME') + '*'" label-for="name">
          <b-form-input
            id="name"
            v-model="v$.newDossier.name.$model"
            :state="validateState('name')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="name-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newDossier.name.$errors"
            id="name-feedback"
          ></error-handle>
          <div v-if="erreurlist.name" class="error-message">
            <ul v-if="Array.isArray(erreurlist.name)">
              <span v-for="(e, index) in erreurlist.name" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.name }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('DELEGATAIRE') + '*'">
          <searchInput
            :list="getOcrDelegataires"
            label="name"
            :multiple="false"
            :searchFunc="OcrDelegataires"
            @searchfilter="selectDelegataire($event)"
            :placeholder="$t('DELEGATAIRE')"
            :filtre="{ entreprise: getOnlineUser.entreprise.id }"
          >
          </searchInput>
          <div v-if="erreurlist.delegataire" class="error-message">
            <ul v-if="Array.isArray(erreurlist.delegataire)">
              <span v-for="(e, index) in erreurlist.delegataire" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.delegataire }}</span>
          </div>
          <div v-if="v$.newDossier.delegataire.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>

        <b-form-group :label="$t('CUSTOMER') + '*'">
          <searchInput
            :list="getAllclients"
            :loader="getclientLoading"
            label="nom"
            :multiple="false"
            :searchFunc="all_clients"
            @searchfilter="selectClient($event)"
            :placeholder="$t('CUSTOMER')"
            :filtre="{ entreprise: getOnlineUser.entreprise.id }"
          >
          </searchInput>
          <div v-if="erreurlist.client" class="error-message">
            <ul v-if="Array.isArray(erreurlist.client)">
              <span v-for="(e, index) in erreurlist.client" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.client }}</span>
          </div>
          <div v-if="v$.newDossier.client.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>

        <b-form-group :label="$t('Description')">
          <b-form-textarea
            v-model="v$.newDossier.description.$model"
            :state="validateState('description')"
            :placeholder="$t('Description')"
            maxlength="1000"
            rows="3"
            required
          ></b-form-textarea>
          <error-handle
            :list="v$.newDossier.description.$errors"
            id="description-feedback"
          ></error-handle>
          <div v-if="erreurlist.description" class="error-message">
            <ul v-if="Array.isArray(erreurlist.description)">
              <span v-for="(e, index) in erreurlist.description" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.description }}</span>
          </div>
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRE">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingdossier" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
    searchInput: () => import("@/components/ui/searchInput"),
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      erreurlist: {
        name: null,
        delegataire: null,
        client: null,
        description: null,
      },
      newDossier: {
        name: null,
        delegataire: null,
        client: null,
        description: null,
      },
      galleryUrl: domains.gallery,
      page: 1,
      per_page: 1000,
      errorS: false,
      box: "",
    };
  },
  validations() {
    return {
      newDossier: {
        name: { required, maxLength: maxLength(100) },
        delegataire: {
          required,
        },
        client: { required },
        description: { maxLength: maxLength(1000) },
      },
    };
  },
  methods: {
    ...mapActions(["store_dossier", "OcrDelegataires", "all_clients"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newDossier[name];
      return $dirty ? !$error : null;
    },

    customLabel({ title }) {
      return `${title}`;
    },
    async addPRE() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        name: null,
        delegataire: null,
        client: null,
        description: null,
      };
      const data = {
        name: this.newDossier.name,
        ocr_delegataire_id: this.newDossier.delegataire.id,
        client_id: this.newDossier.client.id,
        entreprise_id: this.getOnlineUser.entreprise.id,
        description: this.newDossier.description,
      };
      await this.store_dossier(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["dossierModal"].hide();
      this.newDossier = {
        name: null,
        delegataire: null,
        client: null,
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        name: null,
        delegataire: null,
        client: null,
      };
    },

    close(bv) {
      if (this.$refs["dossierModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },

    selectDelegataire(item) {
      this.newDossier.delegataire = item;
    },

    selectClient(item) {
      this.newDossier.client = item;
    },
  },
  mounted() {
    this.OcrDelegataires({ page: this.page, per_page: this.perPage });
    this.all_clients({ page: this.page, per_page: this.perPage });
  },

  computed: {
    ...mapGetters([
      "getOcrDelegataires",
      "getLoadingdossier",
      "getOnlineUser",
      "getAllclients",
      "getclientLoading",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
